import Image from "next/image";
import React from "react";

type ImageWithTextProps = {
  sectionHeading?: string;
  title: string;
  description: string;
  icon?: any;
  image: any;
  direction: "row" | "row-reverse";
};

const ImageWithText = ({
  sectionHeading,
  title,
  description,
  icon,
  image,
  direction,
}: ImageWithTextProps) => {
  return (
    <section>
      <div className="container llg:py-[15px] py-4">
        {sectionHeading && (
          <div className="flex justify-center mb-20 max-w-3xl mx-auto  pb-8 lg:mb-12 md:mb-7">
            <h2 className="h2 text-center">{sectionHeading}</h2>
          </div>
        )}
        <div
          className={`flex items-center lg:flex-col flex-${direction} gap-10 p-[60px] lg:p-[40px] sm:p-[20px] sm:py-[40px]  bg-image-with-text rounded-[108px] lg:rounded-[60px] md:rounded-[30px]   `}
        >
          <div className="w-[38%] lg:w-[100%]">
            <div className="bg-icon shadow-icon rounded-2xl w-16 h-16 inline-flex items-center justify-center mb-10 backdrop-blur-[50px]">
              {icon}
            </div>
            <h3 className="h3 mb-5">{title}</h3>
            <p className="text-xl leading-relaxed text-lightGray-100/50">
              {description}
            </p>
          </div>
          <div className="w-[62%] lg:w-[100%]">
            <div
              className={`max-w-[512px] lg:max-w-[100%] ${
                direction == "row" ? "ms-auto" : "me-auto"
              }`}
            >
              <Image src={image} height={420} width={1200} alt={title} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageWithText;
