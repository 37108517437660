import React from "react";
import { Illustration } from "modules/FeaturesModule/Component/Illustration";
import videoFrame2 from "../public/legal-Images/webpImages/videoFrame2.webp";
import centerdimg from "../public/doc-processing-images/centered-task-list.webp";
import QuestionsLegal from "modules/FeaturesModule/Component/QuestionsLegal";
import { useRouter } from "next/router";

type FaqProps = {
  title: string;
  description: string;
  buttonLabel: string;
  faqs: { question: string; answer: string }[]
};

const FaqSection = ({ title, description, buttonLabel, faqs }: FaqProps) => {
  const router = useRouter();
  return (
    <div className="container mt-20  pt-5">
      <div className="flex  gap-[5rem] lg:flex-col ">
        <div className="w-3/6 lg:w-full">
          <h4 className="bg-landing-page-headline bg-clip-text text-transparent  text-[48px] font-extrabold leading-[56px] opacity-90 font-mont tracking-tighter">
            {title}
          </h4>
          <p className="font-mont font-semibold text-[20px] leading-[32px] mt-[28px] mb-[40px]  text-[rgba(248,248,248,0.50)] opacity-80 ">
            {description}
          </p>
          <button className="subscribe-now-btn" onClick= {() => router.push("contact")}>{buttonLabel}</button>
        </div>
        <div className="w-3/6 lg:w-full">
          <div className="flex flex-col  ">
            {/* <div className=" mx-auto my-8"> */}
            {faqs.map((item, index) => (
              <QuestionsLegal
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>

  );
};

export default FaqSection;
