import { useTranslation } from "react-i18next";
import { useColorMode } from "@chakra-ui/color-mode";

const FeatureData = () => {
  const { t } = useTranslation("translation");
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";
  const choiceData = [
    {
       imgUrl: 
      //  isDarkMode
      //   ?"https://storage.googleapis.com/lokichat-profile/app_hq_visuals1.png"
        "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/1.webp",
      
        heading: t("h_q_visuals"),
      desc: t("h_q_visuals_desc"),
    },
    {
       imgUrl: 
      //  isDarkMode
      // ?"https://storage.googleapis.com/lokichat-profile/app_creative_vers1.png"
      "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/2.webp",
      heading: t("Creative_vers"),
      desc: t("Creative_vers_desc"),
    },
    {
      imgUrl:"https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/3.webp",
      heading: t("Customize_creation"),
      desc: t("Customize_creation_desc"),
    },
  ];
  const profileChoiceData = [
    {
      heading: t("why_profiles_head1"),
      desc: t("why_profiles_desc1"),
    },
    {
      heading: t("why_profiles_head2"),
      desc: t("why_profiles_desc2"),
    },
    {
      heading: t("why_profiles_head3"),
      desc: t("why_profiles_desc3"),
    },
  ];
  const TexttoSpeechchoiceData = [
    {
      imgUrl: "https://storage.googleapis.com/lokichat-profile2/TTS_Landing_pages/1.webp",
      heading: t("guest_voice"),
      desc: t("guest_voice_desc"),
    },
    {
      imgUrl: "https://storage.googleapis.com/lokichat-profile2/TTS_Landing_pages/2.webp",
      heading: t("guest_vers"),
      desc: t("guest_vers_desc"),
    },
    {
      imgUrl: "https://storage.googleapis.com/lokichat-profile2/TTS_Landing_pages/3.webp",
      heading: t("guest_custom"),
      desc: t("guest_custom_desc"),
    },
  ];
  const ChatWithDocchoiceData = [
    {
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/ChatDoc_landing/1.webp",
      heading: t("chat_doc_guest_voice"),
      desc: t("chat_doc_guest_voice_desc"),
    },
    {
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/ChatDoc_landing/2.webp",
      heading: t("chat_doc_guest_vers"),
      desc: t("chat_doc_guest_vers_desc"),
    },
    {
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/ChatDoc_landing/3.webp",
      heading: t("chat_doc_guest_custom"),
      desc: t("chat_doc_guest_custom_desc"),
    },
  ];
  const docChoiceData = [
    {
      imgUrl: isDarkMode
        ? "https://storage.googleapis.com/lokichat-profile/app_guest_acc_doc1.png"
        : "https://storage.googleapis.com/lokichat-profile/app_guest_acc_doc_light1.png",
      heading: t("doc_heading_acc"),
      desc: t("doc_heading_acc_desc"),
    },
    {
      imgUrl: isDarkMode
        ? "https://storage.googleapis.com/lokichat-profile/app_guest_wide_doc2.png"
        : "https://storage.googleapis.com/lokichat-profile/translator_2_light.png",
      heading: t("doc_heading_wide"),
      desc: t("doc_heading_wide_desc"),
    },
    {
      imgUrl: isDarkMode
        ? "https://storage.googleapis.com/lokichat-profile/app_guest_cust_doc2.png"
        : "https://storage.googleapis.com/lokichat-profile/app_guest_cust_doc_light1.png",
      heading: t("doc_heading_cus"),
      desc: t("doc_heading_cus_desc"),
    },
  ];
  const transcriptionChoiceData = [
    {
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/Transcribe_landing/1.webp",
      heading: t("High_Accuracy"),
      desc: t("accu_desc"),
    },
    {
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/Transcribe_landing/2.webp",
      heading: t("multi_lan"),
      desc: t("multi_lan_desc"),
    },
    {
      imgUrl:"https://storage.googleapis.com/lokichat-profile2/Transcribe_landing/3.webp",
      heading: t("Customizable_Output"),
      desc: t("custom_output_desc"),
    },
  ];
  const transcriptionUseCaseData = [
    {
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/Transcribe_landing/4.webp",
      heading: t("guest_meet"),
      desc: t("guest_meet_desc"),
    },
    {
      imgUrl: "https://storage.googleapis.com/lokichat-profile2/Transcribe_landing/5.webp",
      heading: t("guest_cont"),
      desc: t("guest_cont_desc"),
    },
    {
      imgUrl: "https://storage.googleapis.com/lokichat-profile2/Transcribe_landing/6.webp ",
      heading: t("guest_edu"),
      desc: t("guest_edu_desc"),
    },
  ];
  const transcriptionGuideData = [
    {
      id: 1,
      heading: t("upload"),
      desc: t("quest_trans_guide"),
    },
    {
      id: 2,
      heading: t("cli_trans"),
      desc: t("cli_trans_desc"),
    },
    {
      id: 3,
      heading: t("Download_trans"),
      desc: t("Download_trans_desc"),
    },
  ];
  const ChatWithDocGuideData = [
    {
      id: 1,
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/ChatDoc_landing/4.webp",
      desc: t("guest_chat_doc_guide_desc_1"),
    },
    {
      id: 2,
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/ChatDoc_landing/5.webp",
      desc: t("guest_chat_doc_guide_desc_2"),
    },
    {
      id: 3,
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/ChatDoc_landing/7.webp",
      desc: t("guest_chat_doc_guide_desc_3"),
    },
    {
      id: 4,
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/ChatDoc_landing/6.webp",
      desc: t("guest_chat_doc_guide_desc_4"),
    },
  ];
  const useCaseData = [
    {
      imgUrl:"https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/7.webp",
      heading: t("content_creation"),
      desc: t("content_creation_desc"),
    },
    {
      imgUrl:"https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/8.webp",
      
      heading: t("Marketing"),
      desc: t("Marketing_desc"),
    },
    {
      imgUrl:"https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/9.webp",
      heading: t("education_marketing"),
      desc: t("education_desc"),
    },
  ];
  const ComprehensiveData = [
    {
      title: t("comprehensive_title1"),
      description: t("comprehensive_desc1"),
      bgColor: "#9757D7",
    },
    {
      title: t("comprehensive_title2"),
      description: t("comprehensive_desc2"),
      bgColor: "#EF466F",
    },
    {
      title: t("comprehensive_title3"),
      description: t("comprehensive_desc3"),
      bgColor: "#45B26B",
    },
  ];
  const ChatWithDocUseCaseData = [
    {
      title: t("guest_chat_doc_useCase_head_1"),
      description: t("guest_chat_doc_useCase_desc_1"),
    },
    {
      title: t("guest_chat_doc_useCase_head_2"),
      description: t("guest_chat_doc_useCase_desc_2"),
    },
    {
      title: t("guest_chat_doc_useCase_head_3"),
      description: t("guest_chat_doc_useCase_desc_3"),
    },
  ];
  const questionAnswers = [
    {
      question: "What is AI text to image?",
      answer:
        "AI <strong>text to image</strong> technology converts textual descriptions into visual images using advanced algorithms.",
    },
    {
      question: "Do I own the copyrights of the image?",
      answer:
        "Yes, all <strong>AI generated</strong> images are yours to use freely.",
    },
    {
      question: "How can I write the best prompt to get the best AI images?",
      answer:
        "Use <strong>Loki's AI prompt advisor</strong> to craft the perfect description for optimal results.",
    },
    {
      question: "Is quality suitable for printing?",
      answer:
        "Absolutely, with 2K and 4K quality, your <strong>AI images</strong> are perfect for both digital and print media.",
    },
    {
      question: "Where are my files stored?",
      answer:
        "Your <strong>AI generated</strong> images are securely stored in your account for easy access and download.",
    },
    {
      question: "Can Loki also write captions in the text-to-image feature?",
      answer: "Yes, you can add and edit captions to accompany your images.",
    },
  ];
  const TexttospeechquestionAnswers = [
    {
      question: "Is Loki’s Text to Speech free?",
      answer:
        "We offer a free trial for new users. Explore our subscription plans for unlimited access.",
    },
    {
      question: "How many languages does Loki’s AI voice Generator support?",
      answer:
        "Loki supports over 140 languages, ensuring you can communicate in almost any language.",
    },
    {
      question: "Can I choose different voices?",
      answer:
        "Yes, you can select from a variety of voices to match your desired tone and style.",
    },
    {
      question: "How do I subscribe?",
      answer:
        "Visit our subscription page, choose your plan, and follow the easy steps to get started.",
    },
    {
      question: "What quality can I expect from Loki’s AI voice generator?",
      answer:
        "Expect studio-quality audio that’s clear, natural, and perfect for any professional or personal use.",
    },
  ];
  const docquestionAnswers = [
    // {
    //   question: "How does Loki ensure accurate translations?",
    //   answer:
    //     "How does Loki ensure accurate translations?",
    // },
    // {
    //   question: "Does it maintain original formatting for all document types?",
    //   answer:
    //     "Does it maintain original formatting for all document types?",
    // },
    {
      question: "What file types can I translate with Loki?",
      answer:
        "Loki supports formats such as PDF and DOCX, allowing for accurate translation without loss of formatting.",
    },
    {
      question: "Are my documents secure during the translation process?",
      answer:
        "Absolutely. Loki uses advanced encryption and does not store any files on its servers, ensuring maximum privacy and security.",
    },
    {
      question: "How long does it take to complete a translation?",
      answer:
        "Translations are completed in seconds, regardless of document size or language selected.",
    },
    {
      question: "Can I translate files in more one language at a time?",
      answer:"Currently, Loki allows you to select one target language per translation, but you can translate the same document into multiple languages consecutively.",
    },
  ];
  const TransquestionAnswers = [
    {
      question: "Is Loki’s AI transcription service free?",
      answer:
        "We offer a free trial for new users. Explore our subscription plans for unlimited access to transcription services.",
    },
    {
      question: "How accurate are the transcriptions?",
      answer:
        "Loki’s AI transcription tool is designed to deliver highly accurate transcriptions, minimizing errors and ensuring clarity.",
    },
    {
      question: "Can I transcribe audio to text in multiple languages?",
      answer:
        "Yes, Loki supports transcription in multiple languages, catering to a global audience.",
    },
    {
      question:
        "How do I subscribe to Loki’s Video or Audio to Text Converter?",
      answer:
        "Visit our subscription page, choose your plan, and follow the easy steps to get started.",
    },
    {
      question: "Can I edit the AI transcriptions after they are generated?",
      answer:
        "Absolutely, you can review and make any necessary edits to ensure the final text meets your needs.",
    },
  ];
  const chatwithdocquestionAnswer = [
    {
      question: "Is Loki’s AI chat feature free?",
      answer:
        "We offer a free trial for new users. Explore our subscription plans for unlimited access.",
    },
    {
      question: "What types of documents can I chat with?",
      answer:
        "Loki supports various document formats including PDFs, Word documents, and more.",
    },
    {
      question: "How accurate is the AI in understanding document content?",
      answer:
        "Our advanced AI is designed to provide precise and relevant responses, ensuring high accuracy.",
    },
    {
      question: "Is Loki’s AI Chat Multilingual?",
      answer:
        "Yes, Loki supports multiple languages, making it versatile for global users.",
    },
    {
      question: "How do I subscribe to Loki’s documents AI chat service?",
      answer:
        "Visit our subscription page, choose your plan, and follow the easy steps to get started.",
    },
  ];
  const mainquestionAnswer = [
    {
      question: "What is Loki?",
      answer:
        "Loki is an AI-powered suite of AI tools online designed to enhance productivity and creativity for professionals, students, and creatives.",
    },
    {
      question: "How do I use the AI tools?",
      answer:
        "Select the tool you need, upload your document, and let Loki handle the rest. It’s that simple.",
    },
    {
      question: "Is Loki Multilingual?",
      answer:
        "Yes, Loki supports multiple languages, making it easy to break language barriers and expand your reach globally.",
    },
    {
      question: "Can I use Loki’s AI Tools on Mobile Apps?",
      answer:
        "Absolutely! Download Loki from the App Store or Google Play to access all AI Tools features on the go.",
    },
    {
      question: "Is it free to use Loki AI Tools?",
      answer:
        "Loki offers a range of subscription plans to suit your needs, including a free trial for new users.",
    },
    {
      question: "Can I trust Loki with sensitive or confidential documents?",
      answer:
        "Absolutely. Our commitment to data privacy and security is unwavering, and you can trust Loki to handle your documents with the utmost care and discretion.",
    },
    {
      question:
        "Do I need to manually sync my data between different platforms?",
      answer:
        "No, manual syncing is not required. Loki's intelligent syncing mechanism takes care of keeping your data in sync across all platforms automatically. This ensures that you can seamlessly switch between devices without worrying about data discrepancies.",
    },
  ];
  const ProfilesQAData = [
    {
      question: t("profile_question_1"),
      answer: t("profile_answer_1"),
    },
    {
      question: t("profile_question_2"),
      answer: t("profile_answer_2"),
    },
    {
      question: t("profile_question_3"),
      answer: t("profile_answer_3"),
    },
    {
      question: t("profile_question_4"),
      answer: t("profile_answer_4"),
    },
  ];
  const ProfilesGuideData = [
    {
      id: 1,
      heading: t("how_profiles_head1"),
      desc: t("how_profiles_desc1"),
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/Profile_landing/1.webp",
    },
    {
      id: 2,
      heading: t("how_profiles_head2"),
      desc: t("how_profiles_desc2"),
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/Profile_landing/2.webp",
    },
    {
      id: 3,
      heading: t("how_profiles_head3"),
      desc: t("how_profiles_desc3"),
      imgUrl:
        "https://storage.googleapis.com/lokichat-profile2/Profile_landing/3.webp",
    },
  ];
  // ? "https://storage.googleapis.com/lokichat-profile/Profile_1_white.png"
  // : "https://storage.googleapis.com/lokichat-profile/profiles_1.png",
  const ProfilesUseCaseData = [
    {
      title: t("profiles_uc_head1"),
      description: t("profiles_uc_desc1"),
      imgUrl:"https://storage.googleapis.com/lokichat-profile/profiles_1.png"
     
    },
    {
      title: t("profiles_uc_head2"),
      description: t("profiles_uc_desc2"),
      imgUrl: 
       "https://storage.googleapis.com/lokichat-profile2/Profile_landing/5.webp"
        // "https://storage.googleapis.com/lokichat-profile/Profile_2.png",
    },
    {
      title: t("profiles_uc_head3"),
      description: t("profiles_uc_desc3"),
      imgUrl:"https://storage.googleapis.com/lokichat-profile2/Profile_landing/6.webp"
      
    },
  ];
  // const LokiProfileBackgroundImages = [""]
  return {
    choiceData,
    useCaseData,
    ComprehensiveData,
    questionAnswers,
    TexttoSpeechchoiceData,
    TexttospeechquestionAnswers,
    docChoiceData,
    docquestionAnswers,
    transcriptionChoiceData,
    TransquestionAnswers,
    transcriptionGuideData,
    ChatWithDocGuideData,
    transcriptionUseCaseData,
    ChatWithDocUseCaseData,
    ChatWithDocchoiceData,
    chatwithdocquestionAnswer,
    profileChoiceData,
    ProfilesGuideData,
    ProfilesUseCaseData,
    ProfilesQAData,
    mainquestionAnswer,
  };
};

export default FeatureData;
