import { FaqPlusIcon, FaqMinusIcon } from "@/components/SVGs";
import React, { useState, useRef } from "react";

type QuestionProps = {
  question?: string;
  answer?: string;
};

const QuestionsLegal = ({ question, answer }: QuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`accordian-header mb-5${isOpen ? " accordian-open" : ""}`}
      // style={{
      //   display: "flex",
      //   padding: "1px",
      //   flexDirection: "column",
      //   alignItems: "flex-start",
      //   gap: "4px",
      //   alignSelf: "stretch",
      //   borderRadius: "25px",
      //   border: "3px solid rgb(52 48 48 / 70%)",
      //   width:"100% !important",

      //   background: isOpen
      //     ? "rgba(248, 248, 248, 0.10)"
      //     : "var(--neutral-neutral-22, rgba(248, 248, 248, 0.02))",

      //   boxShadow: isOpen
      //     ? "none"
      //     : "1px 2px 4px 0px rgba(248, 248, 248, 0.06) inset",
      // }}
    >
      <div>
      <button
        onClick={toggleOpen}
        className={` flex justify-between gap-2 flex justify-center items-center w-full 
        `}
      >
        <span
          className=""
          style={{
            // fontSize: isOpen ?"14px":"15px",
            // lineHeight: "34x",
            // letterSpacing: "-0.96px",
            background:
              "var(--Landing-page-Headline, linear-gradient(93deg, rgba(248, 248, 248, 0.90) 0.1%, rgba(248, 248, 248, 0.50) 100.1%))",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "rgba(248, 248, 248, 0.95)",
            // fontWeight: isOpen ?"600":"400",
            opacity: isOpen ? "0.7" : "0.6",
            width:"85%",
            textAlign:"start",
          }}
        >
          <p style={{
              whiteSpace:"nowrap",
              overflow:"hidden",
              textOverflow:"ellipsis",
              width:"100%",
          }}>

          {question}
          </p>
        </span>
        {isOpen ? <FaqMinusIcon /> : <FaqPlusIcon />}
      </button>
      </div>
   
      <div
        ref={contentRef}
        className={` overflow-hidden transition-all duration-500 ease-in-out ${
          isOpen ? "max-h-[1000px]" : "max-h-0"
        }`}
        style={{
          maxHeight: isOpen ? `${contentRef?.current?.scrollHeight}px` : "0",
        }}
      >
        <div
          className="  font-Montserrat font-Montserrat font-normal text-[16px]  leading-[1.2]   sm:text-[14px] "
          style={{
            color: "var(--Text-Tertiary, rgba(248, 248, 248, 0.50))",
            // lineHeight: "15px",
          }}
          dangerouslySetInnerHTML={{ __html: answer || "" }}
        />
      </div>
    </div>
  );
};

export default QuestionsLegal;
