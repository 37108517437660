import React from "react";
import { Illustration } from "modules/FeaturesModule/Component/Illustration";
import videoFrame2 from "../public/legal-Images/webpImages/videoFrame2.webp";
import Image from "next/image";
import { handleGetStartedClick } from "lib/getStartedUserClick";
import { useRouter } from "next/router";

type FooterBannerProps = {
  title: string;
  description: string;
  buttonLabel: string;
  buttonURL: string;
  image: any;
}

const FooterBanner = ({ title, description, buttonLabel, buttonURL, image }: FooterBannerProps) => {
  const router = useRouter();
  const handleStartClick = () => {
    handleGetStartedClick(router);
  };
  return (
    <div className="container mt-20 pt-20 lg:mt-12 md:mt-7 lg:pt-12 md:pt-7 ">
      <div className="interact-with-section">
        <div className="relative mt-3">
          <h2 className="bg-landing-page-headline bg-clip-text text-transparent text-center   text-[64px] font-extrabold line-height-70 opacity-90 font-mont tracking-tighter lg:text-[30px] ">
            {title}
          </h2>
          <p className="font-mont font-semibold text-[16px] leading-[24px] text-center mt-[20px] mb-[26px]  text-[rgba(248,248,248,0.50)] opacity-80 ">
            {description}
          </p>
          <div className="flex ">
            <button 
              className="subscribe-now-btn" 
              onClick={handleStartClick}
            >
              {buttonLabel}
            </button>
          </div>
          <div className="flex justify-between lg:hidden">
            <div className="upload-doc-btn-box">
              <button className="upload-doc-btn">Upload Doc</button>
            </div>
            <div className="Interact-doc-btn-box">
              <button className="Interact-doc-btn">Interact</button>
            </div>
          </div>
        </div>

        <div className="relative">
          <Image
            src={image}
            className="m-auto"
            alt=""
          />
          <div className="download-doc-btn-box lg:hidden">
            <button className="download-doc-btn">Download</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBanner;
